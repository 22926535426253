import React, { useState } from "react";

function ConsultationForm() {
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [phone, setPhone] = useState("");
  const [insuranceType, setInsuranceType] = useState("");
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState("");
  const [showTermsModal, setShowTermsModal] = useState(false); // 개인정보 동의 팝업 상태

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^(010-\d{4}-\d{4}|010\d{8})$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(phone)) {
      setPhoneError("유효한 전화번호를 입력해주세요. (형식: 010-1234-5678 또는 01012345678)");
      return;
    }

    setPhoneError("");

    if (!consent) {
      setConsentError("개인정보 사용에 동의하셔야 합니다.");
      return;
    }

    setConsentError("");

    const consultationData = { name, region, phone, insuranceType, message };

    try {
      const response = await fetch("http://0.0.0.0:5001/api/consultation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(consultationData),
      });
      const result = await response.json();
      alert("감사합니다. 상담 요청이 접수되었습니다.");

      setName("");
      setRegion("");
      setPhone("");
      setInsuranceType("");
      setMessage("");
      setConsent(false);

      setResponseMessage(result.message);
    } catch (error) {
      setResponseMessage("양식 제출 중 오류가 발생했습니다. 다시 시도해주세요.");
    }
  };

  return (
    <div style={{ backgroundColor: "#e0f7fa", padding: "20px", borderRadius: "8px", maxWidth: "600px", margin: "auto" }}>
      <h2 style={{ color: "#0277bd", textAlign: "center" }}>보험 상담 요청</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "10px" }}>
          <label>
            이름:
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              style={{ display: "block", width: "100%" }}
            />
          </label>
        </div>

        {/* 지역 선택 필드 */}
        <div style={{ marginBottom: "10px" }}>
          <label>
            지역:
            <select
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              required
              style={{ display: "block", width: "100%" }}
            >
              <option value="">지역 선택</option>
              <option value="서울">서울</option>
              <option value="경기도">경기도</option>
              <option value="부산">부산</option>
              <option value="대구">대구</option>
              <option value="인천">인천</option>
              <option value="광주">광주</option>
              <option value="대전">대전</option>
              <option value="울산">울산</option>
              <option value="세종">세종</option>
              <option value="강원도">강원도</option>
              <option value="충청북도">충청북도</option>
              <option value="충청남도">충청남도</option>
              <option value="전라북도">전라북도</option>
              <option value="전라남도">전라남도</option>
              <option value="경상북도">경상북도</option>
              <option value="경상남도">경상남도</option>
              <option value="제주도">제주도</option>
            </select>
          </label>
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label>
            전화번호:
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              style={{ display: "block", width: "100%" }}
            />
            {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}
          </label>
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label>
            보험 유형:
            <select
              value={insuranceType}
              onChange={(e) => setInsuranceType(e.target.value)}
              required
              style={{ display: "block", width: "100%" }}
            >
              <option value="">보험 유형 선택</option>
              <option value="Health">건강 보험</option>
              <option value="Life">생명 보험</option>
              <option value="Auto">자동차 보험</option>
            </select>
          </label>
        </div>

        <div style={{ marginBottom: "10px" }}>
          <label>
            메시지:
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              style={{ display: "block", width: "100%", height: "100px" }}
            />
          </label>
        </div>

        {/* 개인정보 사용 동의 체크박스 */}
        <div style={{ marginBottom: "10px", alignItems: "left" }}>
          <input
            label=""
            type="checkbox"
            checked={consent}
            onChange={(e) => setConsent(e.target.checked)}
            style={{ marginRight: "10px" }}
          />
          <label>
            개인정보 사용에 동의합니다. (필수)
          </label>
          {consentError && <p style={{ color: "red" }}>{consentError}</p>}
        </div>

        {/* 개인정보 약관 보기 */}
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <button type="button" onClick={() => setShowTermsModal(true)}>
            개인정보 활용 약관 보기
          </button>
        </div>

        {/* 제출 버튼 */}
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <button type="submit">제출</button>
        </div>
      </form>

      {responseMessage && <p style={{ textAlign: "center" }}>{responseMessage}</p>}

      {/* 개인정보 약관 팝업 */}
      {showTermsModal && (
        <div style={{
          position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)",
          display: "flex", justifyContent: "center", alignItems: "center"
        }}>
          <div style={{
            backgroundColor: "white", padding: "20px", borderRadius: "8px", width: "60%", maxHeight: "80%", overflowY: "auto",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)"
          }}>
            <h2>개인정보 활용 약관</h2>
            <p><strong>1. 수집하는 개인정보 항목 및 수집방법</strong></p>
            <p>- 이름, 전화번호, 보험 유형, 상담 메시지<br />- 수집 방법: 웹사이트 폼을 통한 직접 입력</p>
            <p><strong>2. 개인정보의 수집 및 이용 목적</strong></p>
            <p>- 보험 상담 서비스 제공<br />- 상담 요청 처리 및 관련 서비스 제공</p>
            <p><strong>3. 개인정보의 보유 및 이용 기간</strong></p>
            <p>- 개인정보는 수집 및 이용 목적이 달성된 후 지체 없이 파기됩니다.<br />- 법적 의무에 따라 일정 기간 보관될 수 있습니다.</p>
            <p><strong>4. 개인정보의 제3자 제공</strong></p>
            <p>- 동의 없이 제3자에게 제공되지 않습니다.<br />- 법적 의무에 따라 예외가 있을 수 있습니다.</p>
            <button onClick={() => setShowTermsModal(false)}>닫기</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConsultationForm;
