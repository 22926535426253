import React from "react";
import ConsultationForm from "./ConsultationForm";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header>
        <h1>보험 상담 서비스</h1>
        <p>전문가에게 맞춤형 보험 상담을 받아보세요.</p>
      </header>
      <main>
        <ConsultationForm />
      </main>
      <footer>
        <p>&copy; 2024 보험 상담 서비스. 모든 권리 보유.</p>
      </footer>
    </div>
  );
}

export default App;
